
import React from 'react';
import Layout from './features/layout/Layout';
import CustomNoteTemplate from './features/pages/CustomNoteTemplate';
import Analytics from './features/pages/Analytics';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AllNotes from './features/pages/AllNotes';
import { SignedIn, SignedOut } from '@clerk/clerk-react';
import SignUp from './features/pages/SignUp';
import SignIn from './features/pages/SignIn';
import NoteDetails from './features/pages/NoteDetails';
import Onboarding from './features/pages/OnBoarding';
import SendTokenMobile from './features/pages/SignInMobile';
import SingedInReRouting from './components/SingedInReRouting';
import TestComponnet from './components/NewTextArea';
import SignedOutRouting from './components/SignedOutRouting';
import Subscription from './components/subscription/Subscription';
import NewNoteDetailsContent from './components/newNoteDetailsContent/NewNoteDetailsContent';
function App() {
  return (
    <BrowserRouter>
      <SignedOut>
        <Routes>
            <Route path='/' element={<SignIn />} />
            <Route path='/sign-up' element={<SignUp />} />
            <Route path='*' element={<SignedOutRouting />} />
          </Routes>
        </SignedOut>
        <SignedIn>
          <Routes>
            <Route path='/on-boarding' element={<Onboarding />} />
            <Route path='/auth-token' element={<SendTokenMobile />} />
            <Route element={<Layout />}>
              <Route path='/test' element={<NewNoteDetailsContent />} />
              <Route path='/all-notes' element={<AllNotes />} />
              <Route path='/all-notes/:id' element={<NoteDetails />} />
              <Route path='/custom-note-template' element={<CustomNoteTemplate /> } />
              <Route path='/analytics' element={<Analytics/> } />
              {/* <Route path='/subscription' element={<Subscription />} /> */}
            </Route>
            <Route path='*' element={<SingedInReRouting />} />
        </Routes>
        </SignedIn>
    </BrowserRouter>
  );
}

export default App;
