import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
} from '@mui/material';
import Chart from 'react-apexcharts';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import discharge from '../../assets/discharge.png';
import admit from '../../assets/admit.png';
import admission from '../../assets/admission.png';
import records from '../../assets/records.png';
import medical from '../../assets/medical.png';
import history from '../../assets/history.png';
import care from '../../assets/care.png';

// Data for the radial bars
const data = [
  {
    name: 'Discharge',
    percentage: 55,
    detail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imgSrc: discharge,
  },
  {
    name: 'Admit',
    percentage: 75,
    detail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imgSrc: admit,
  },
  {
    name: 'Consider for admission',
    percentage: 65,
    detail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imgSrc: admission,
  },
  {
    name: 'External Records',
    percentage: 90,
    detail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imgSrc: records,
  },
  {
    name: 'Additional History',
    percentage: 50,
    detail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imgSrc: history,
  },
  {
    name: 'Chronic Medical Conditions',
    percentage: 65,
    detail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imgSrc: medical,
  },
  {
    name: 'Care Discussed',
    percentage: 80,
    detail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    imgSrc: care,
  },
];

const fillColor = '#2E6FF3';
const trackColor = '#cfcfcf';

const Analytics = () => {
  const [showContent, setShowContent] = React.useState(
    data.map(() => true) 
  );

  const toggleContentVisibility = (index) => {
    setShowContent((prev) =>
      prev.map((visible, i) => (i === index ? !visible : visible))
    );
  };

  return (
    <Box sx={{ px: 4, py: 1, backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
      <Typography
        variant='h5'
        sx={{
          fontWeight: 'bold',
          fontSize: '32px',
          textAlign: 'start',
          mb: 1,
          color: '#202224',
        }}
      >
        Analytics
      </Typography>
      <Typography
        variant='h5'
        sx={{
          fontWeight: 400,
          textAlign: 'start',
          fontSize: '16px',
          mb: 3,
          color: '#202224',
        }}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi.
      </Typography>

      <Grid container spacing={3} justifyContent='center'>
        {data.map((item, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card
              sx={{
                border: '1px solid #2E6FF3',
                borderRadius: '8px',
                backgroundColor: '#EDF2FF',
                textAlign: 'center',
                width: '357px',
                height: showContent[index] ? '424px' : '70px',
                position: 'relative',
                transition: 'height 0.3s ease',
              }}
            >
              <IconButton
                sx={{
                  color: fillColor,
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  zIndex: 1,
                }}
                onClick={() => toggleContentVisibility(index)}
              >
                {showContent[index] ? <Visibility /> : <VisibilityOff />}
              </IconButton>

              {showContent[index] && (
                <CardContent sx={{ position: 'relative' }}>
                  <Chart
                    options={{
                      chart: {
                        type: 'radialBar',
                      },
                      colors: [fillColor],
                      plotOptions: {
                        radialBar: {
                          hollow: {
                            margin: 0,
                            size: '70%',
                          },
                          track: {
                            background: trackColor,
                            strokeWidth: '100%',
                          },
                          dataLabels: {
                            show: false,
                          },
                        },
                      },
                      labels: [item.name],
                    }}
                    series={[item.percentage]}
                    type='radialBar'
                    height={200}
                  />

                  {/* Image positioned at the center of the radial bar */}
                  <Box
                    component='img'
                    fetchPriority='high'
                    src={item.imgSrc}
                    alt={item.name}
                    sx={{
                      position: 'absolute',
                      top: '30%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: '140px',
                      height: '140px',
                      borderRadius: '50%',
                    }}
                  />

                  <Typography
                    variant='h6'
                    sx={{
                      fontSize: '48px',
                      fontWeight: 400,
                      lineHeight: '65.37px',
                      color: fillColor,
                    }}
                  >
                    {item.percentage}%
                  </Typography>
                  <Typography
                    variant='h6'
                    sx={{
                      fontSize: '24px',
                      fontWeight: 600,
                      lineHeight: '32.68px',
                      color: fillColor,
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    variant='h6'
                    sx={{
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '21.37px',
                      color: '#7A7B7D',
                    }}
                  >
                    {item.detail}
                  </Typography>
                </CardContent>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Analytics;
